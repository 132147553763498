import React from 'react';
import { createTheme, colors } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import './fonts.css';

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<HTMLAnchorElement>((props: { href?: string }, ref) => {
  // eslint-disable-next-line react/prop-types
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href ?? ''} {...other} />;
});

const theme = createTheme({
  palette: {
    primary: { main: '#D32E2F' },
    secondary: { main: '#4B999E' },
    background: { default: '#F8F6F6', paper: 'white' },
    error: { main: colors.red[700] },
    all: { main: '#1d99a1' },
    out: { main: '#86c2b3' },
    in: { main: '#fbc4ac' },
    the: { main: '#faa224' },
    open: { main: '#f37049' },
    icon: { main: colors.grey[600] },
  },
  typography: {
    fontFamily: 'FuturaCyrillic, verdana, arial, sans-serif',
    body1: {
      fontSize: '1.125rem', // 18px
      fontWeight: 300,
    },
    h1: {
      fontWeight: 'bold',
      fontSize: '2.5rem', // 40px
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '1.75rem', // 28px
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.5rem', // 24px
      textTransform: 'uppercase',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.25rem',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiDialogActions: {
      defaultProps: {
        sx: { px: 3, py: 2, justifyContent: 'space-between' },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
  },
});

export default theme;
