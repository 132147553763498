import React, { useState, ReactElement, forwardRef, Ref } from 'react';
import { AppBar, Box, Button, IconButton, Menu, MenuItem, SxProps, Tab, Tabs, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo/text-stack.svg';

interface NavItem {
  name: string;
  path: string;
}

const navItems: NavItem[] = [
  { name: 'Home', path: '/' },
  { name: 'Podcast', path: '/podcast' },
  { name: 'About Us', path: '/about' },
];

const menuWidth = 240;
const tabsHeight = 64;

const DonateButton = ({ sx }: { sx?: SxProps }): ReactElement => {
  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      sx={{ borderRadius: '0', fontSize: (theme) => theme.typography.body1.fontSize, ...sx }}
      href="https://www.venmo.com/u/alloutintheopen"
      target="_blank"
    >
      Donate
    </Button>
  );
};

const TopNavigation = (_props: {}, ref: Ref<HTMLElement>): ReactElement => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const open = Boolean(menuAnchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <AppBar ref={ref} sx={{ backgroundColor: (theme) => theme.palette.background.default }} elevation={0}>
      <Toolbar component="nav">
        <Box display="flex" flex={1}>
          <Box display="inline-block">
            <Link to="/">
              <Box width={{ xs: 35, sm: 45 }} height={tabsHeight} display="flex">
                <Logo />
              </Box>
            </Link>
          </Box>
        </Box>
        <Tabs
          value={location.pathname}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{ display: { xs: 'none', md: 'flex' }, height: tabsHeight }}
        >
          {navItems.map((item) => (
            <Tab
              key={item.path}
              value={item.path}
              href={item.path}
              label={item.name}
              sx={(theme) => ({
                height: tabsHeight,
                fontSize: theme.typography.body1.fontSize,
                '&.Mui-selected': {
                  color: theme.palette.primary,
                },
              })}
            />
          ))}
        </Tabs>

        <DonateButton sx={{ ml: 2, display: { xs: 'none', md: 'inherit' } }} />

        <IconButton
          id="nav-button"
          edge="start"
          onClick={openMenu}
          sx={{ mr: 2, display: { md: 'none' } }}
          aria-controls={open ? 'nav-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Menu
        id="nav-menu"
        anchorEl={menuAnchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'nav-button',
        }}
        sx={{ textAlign: 'center' }}
      >
        {navItems.map((item) => (
          <MenuItem
            key={item.path}
            component={Link}
            to={item.path}
            onClick={closeMenu}
            selected={location.pathname === item.path}
            sx={{ width: menuWidth }}
          >
            {item.name}
          </MenuItem>
        ))}
        <DonateButton sx={{ mt: 3, mb: 2, mx: 'auto' }} />
      </Menu>
    </AppBar>
  );
};

export default forwardRef(TopNavigation);
