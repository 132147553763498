import React, { ReactElement, forwardRef, Ref } from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';

const Footer = (_props: {}, ref: Ref<HTMLElement>): ReactElement => {
  return (
    <Box
      ref={ref}
      py={2}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        borderTop: `2px solid ${theme.palette.background.paper}`,
      })}
    >
      <Container sx={{ textAlign: 'center' }}>
        <Typography variant="caption">
          <Grid container direction="row" justifyContent="center" spacing={0.4}>
            <Grid item>&copy; {new Date().getFullYear()} All Out in the Open. All rights reserved.</Grid>
            <Grid item>All Out in the Open is a 501(c)(3) nonprofit organization.</Grid>
          </Grid>
        </Typography>
      </Container>
    </Box>
  );
};

export default forwardRef(Footer);
